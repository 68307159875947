import React from 'react';
import {Redirect, Route, RouteComponentProps} from 'react-router-dom';
import {NonAuthRoutes} from "../../enumerations";

interface Props {
    Component: React.FC<RouteComponentProps>
    path: string;
    exact?: boolean;
};

const PrivateRoute = ({Component, path, exact = false}: Props): JSX.Element => {
    // TODO needs verify token
    const isAuthed = !!localStorage.getItem('tokens');
    const message = 'Please log in to view this page'
    return (
        <Route
            exact={exact}
            path={path}
            render={(props: RouteComponentProps) =>
                isAuthed ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: NonAuthRoutes.login,
                            state: {
                                message,
                                requestedPath: path
                            }
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;