import React from 'react';


const Login = () => {

  return (
      <div>TODO new login page</div>
  );
}

export default Login;