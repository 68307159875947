import React from 'react';
import {Col, Row} from "react-bootstrap";
import BeerTableView from "./Table";
import TeamsView from "./Teams";
import UserContext from "../context/user";
import MainView from "./Main";

const DashView: React.FC = () => {

    // TODO needs verify token or refactor all to better way handle logged user
    const isAuthed = !!localStorage.getItem('tokens');


    return (
        <UserContext.Provider value={isAuthed}>
            Admin logged
            <div className="p-3">
            <Row>
                <Col xs={12} md={8}>
                    <MainView />
                </Col>
                <Col xs={12} md={4}>
                    <TeamsView/>
                </Col>
            </Row>
        </div>
        </UserContext.Provider>

    )
};

export default DashView;