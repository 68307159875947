import React, {useContext} from 'react';
import Switch from 'react-switch';
import {ThemeContext} from 'styled-components';
import {shade} from 'polished'; // Shade pegar uma cor e aplica um percentual de preto em cima dela
import {Container} from './styles';
import {Link} from "react-router-dom";
import {Grid} from "@mui/material";


interface Props {
    toggleTheme(): void;
}

const Header: React.FC<Props> = ({toggleTheme}) => {
    const {colors, title} = useContext(ThemeContext);

    return (
        <Container>
            <Grid container>
                <Grid item xs={9} sm={5} md={3} spacing={2}>
                    <h4><Link to={"/"}>Jablhol Beerpong</Link></h4>
                </Grid>
                <Grid item xs={12} sm={5} md={7} spacing={2} order={{xs: 3, sm: 2}} style={{textAlign: "center"}}>
                    {/*<TextScroller/>*/}
                </Grid>
                <Grid item xs={3} sm={2} md={2} spacing={2} order={{xs: 2, sm: 3}} style={{textAlign: "right"}}>
                    <Switch
                        //Função para trocar os temas
                        onChange={toggleTheme}
                        checked={title === 'dark'}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        height={10}
                        width={40}
                        handleDiameter={20}
                        offColor={shade(0.15, colors.primary)}
                        onColor={colors.secondary}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default Header;