import {gql} from "@apollo/client";

export const TURNAMENT_INFO_MESSAGE = gql`
{
  turnament{
    id,
    statusType{
      strId,
      message
    },
    maxRounds
  }
}
`

export const TURNAMENT_INFO = gql`
{
  turnament{
    id,
    statusType{
      strId,
    },
    maxRounds
  }
}
`

export const TABLES = gql`
{
  tables{
    id,
    name
  }
}
`

export const GAMES = gql`
query Games($tableId: Int!){
  allGames(tableId: $tableId){
    id,
    game {
      id,
      team1 {
        id, team
      },
      team2 {
        id, team
      }
    },
    table{
      id
    },
    tags
  }
}
`
