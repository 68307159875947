import React from 'react';
import {Col, Row} from "react-bootstrap";
import TeamsView from "./Teams";
import MainView from "./Main";


const HomeView: React.FC = () => {

    return (
        <div className="p-3">
            <Row>
                <Col xs={12} md={7}>
                    <MainView />
                </Col>
                <Col xs={12} md={5}>
                    <TeamsView/>
                </Col>
            </Row>
        </div>
    )
};

export default HomeView;