import styled from 'styled-components';

export const BeerTableStyles = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.text};
  border: 2px solid ${props => props.theme.colors.text};
  border-radius: 15px;
  background: ${props => props.theme.colors.lighter};
  align-items: center;
  margin: 25px 0px;
  padding: 8px;
`;

export const BeerTableStylePlay = styled.div`

  background: ${props => props.theme.colors.third};
  border: 1px solid #000000;
  margin: 5px;
  border-radius: 25px;
  padding: 10px 0px 6px 0px;

`;