import React, {useEffect, useState} from 'react';
import {Badge, Col, Row} from "react-bootstrap";
import usePersistedState from "../utils/usePersistedState";
import {useQuery} from "@apollo/client";
import {gqlTeamResults} from "./gql/teams";
import View404 from "./404";
import TeamsView from "./Teams";
import MainView from "./Main";
import EloGraph from "./statistics/EloGraph";


const TeamStatsView: React.FC = (props: any) => {

    const [refresInt, setRefresInt] = usePersistedState('refreshInt', 60000);
    const [ligaEloStart, setLigaEloStart] = useState<number>(1000);
    const [seasonEloStart, setSeasonEloStart] = useState<number>(1000);
    const [eloStart, setEloStart] = useState<number>(1000);

    const {loading, error, data, refetch} = useQuery(gqlTeamResults, {
        variables: {teamId: props.match.params.id},
        pollInterval: refresInt,
        fetchPolicy: "cache-and-network",
        onCompleted(data){
            if(data && data.teamResults.length > 0){
                if (data.team.id == data.teamResults[0].team1.id) {
                    setEloStart(data.teamResults[0].startPointsTeam1);
                    setLigaEloStart(data.teamResults[0].ligaStartPointsTeam1);
                    setSeasonEloStart(data.teamResults[0].ligaStartPointsTeam1);
                } else {
                    setEloStart(data.teamResults[0].startPointsTeam2);
                    setLigaEloStart(data.teamResults[0].ligaStartPointsTeam2);
                    setSeasonEloStart(data.teamResults[0].ligaStartPointsTeam2);
                }
            }

        }
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    // neexistujici Team show 404
    if (!data.team) return <View404/>

    // helpers for graphs
    let min = 9999;
    let max = 0;
    let ligaMin = 9999;
    let ligaMax = 0;
    let seasonMin = 9999;
    let seasonMax = 0;

    const graphData = (data: any) => {
        let res: Array<any> = [];

        data.teamResults.forEach((game: any, index: number) => {

            if (index == 0) {
                if (data.team.id === game.team1.id) {
                    res.push({name: index + '.', elo: game.startPointsTeam1});

                } else {
                    res.push({name: index + '.', elo: game.startPointsTeam2});
                }

            }

            if (data.team.id === game.team1.id) {
                res.push({name: index + 1 + '.', elo: game.startPointsTeam1 + game.team1EloChange});
                if (game.startPointsTeam1 + game.team1EloChange < min) min = game.startPointsTeam1 + game.team1EloChange;
                if (game.startPointsTeam1 + game.team1EloChange > max) max = game.startPointsTeam1 + game.team1EloChange;
            } else {
                res.push({name: index + 1 + '.', elo: game.startPointsTeam2 + game.team2EloChange});
                if (game.startPointsTeam2 + game.team2EloChange < min) min = game.startPointsTeam2 + game.team2EloChange;
                if (game.startPointsTeam2 + game.team2EloChange > max) max = game.startPointsTea2 + game.team2EloChange;
            }
        });

        return res
    }

    const ligaGraphData = (data: any) => {
        let res: Array<any> = [];

        data.teamResults.forEach((game: any, index: number) => {

            if (index === 0) {
                if (data.team.id === game.team1.id) {
                    res.push({name: index + '.', LigaElo: game.ligaStartPointsTeam1});
                } else {
                    res.push({name: index + '.', LigaElo: game.ligaStartPointsTeam2});
                }
            }

            if (data.team.id === game.team1.id) {
                res.push({name: index + 1 + '.', LigaElo: game.ligaStartPointsTeam1 + game.ligaTeam1EloChange});
                if (game.ligaStartPointsTeam1 + game.ligaTeam1EloChange < ligaMin) ligaMin = game.ligaStartPointsTeam1 + game.ligaTeam1EloChange;
                if (game.ligaStartPointsTeam1 + game.ligaTeam1EloChange > ligaMax) ligaMax = game.ligaStartPointsTeam1 + game.ligaTeam1EloChange;
            } else {
                res.push({name: index + 1 + '.', LigaElo: game.ligaStartPointsTeam2 + game.ligaTeam2EloChange});
                if (game.ligaStartPointsTeam2 + game.ligaTeam2EloChange < ligaMin) ligaMin = game.ligaStartPointsTeam2 + game.ligaTeam2EloChange;
                if (game.ligaStartPointsTeam2 + game.ligaTeam2EloChange > ligaMax) ligaMax = game.ligaStartPointsTea2 + game.ligaTeam2EloChange;
            }
        });

        return res
    }

    const seasonGraphData = (data: any) => {
        let res: Array<any> = [];

        data.teamResults.forEach((game: any, index: number) => {

            if (index === 0) {
                if (data.team.id === game.team1.id) {
                    res.push({name: index + '.', SeasonElo: game.seasonStartPointsTeam1});
                } else {
                    res.push({name: index + '.', SeasonElo: game.seasonStartPointsTeam2});
                }
            }

            if (data.team.id === game.team1.id) {
                res.push({name: index + 1 + '.', SeasonElo: game.seasonStartPointsTeam1 + game.seasonTeam1EloChange});
                if (game.seasonStartPointsTeam1 + game.seasonTeam1EloChange < seasonMin) seasonMin = game.seasonStartPointsTeam1 + game.seasonTeam1EloChange;
                if (game.seasonStartPointsTeam1 + game.seasonTeam1EloChange > seasonMax) seasonMax = game.seasonStartPointsTeam1 + game.seasonTeam1EloChange;
            } else {
                res.push({name: index + 1 + '.', SeasonElo: game.seasonStartPointsTeam2 + game.seasonTeam2EloChange});
                if (game.seasonStartPointsTeam2 + game.seasonTeam2EloChange < seasonMin) seasonMin = game.seasonStartPointsTeam2 + game.seasonTeam2EloChange;
                if (game.seasonStartPointsTeam2 + game.seasonTeam2EloChange > seasonMax) seasonMax = game.seasonStartPointsTea2 + game.seasonTeam2EloChange;
            }
        });

        return res
    }

    const MinutesToPlay = (data: any) => {

        //
    }

    return (
        <div className="p-3">

            <Row>
                <Col xs={12} md={8}>
                    <Row>
                        <Col xs={12} sm={12} md={5}>
                            <Row>
                                <Col><h1>{data.team.team} <Badge variant={"primary"} pill>{data.team.points}</Badge>
                                </h1>
                                     {data.turnament.seasonElo &&
                                    <h4><Badge variant="info">Season ELO: {data.team.seasonPoints}</Badge></h4>}
                                    {data.turnament.ligaElo &&
                                    <h4><Badge variant="secondary">Liga ELO: {data.team.ligaPoints}</Badge></h4>}
                                </Col>
                            </Row>
                            <Row>
                                <Col><h6>{data.team.player1.substr(0,data.team.player1.indexOf(' ')+2)}., {data.team.player2.substr(0,data.team.player2.indexOf(' ')+2)}.</h6></Col>
                            </Row>
                            <Row>
                                <Col><h3>Odehráno {data.team.playedGames}/{data.turnament.maxRounds}</h3></Col>
                            </Row>

                            {data.teamResults.map((game: any) => (
                                <Row key={data.teamResults.id}>
                                    <Col>
                                        <h6>{game.team1.team} vs {game.team2.team} {game.scoreTeam1}:{game.scoreTeam2} &nbsp;
                                            {data.team.team === game.team1.team ?
                                                <ChangedPoints num={game.team1EloChange}
                                                               ligaNum={data.turnament.ligaElo ? game.ligaTeam1EloChange : undefined}
                                                               seasonNum={data.turnament.seasonElo ? game.seasonTeam1EloChange : undefined}/> :
                                                <ChangedPoints num={game.team2EloChange}
                                                               ligaNum={data.turnament.ligaElo ? game.ligaTeam2EloChange : undefined}
                                                               seasonNum={data.turnament.seasonElo ? game.seasonTeam2EloChange : undefined}/>}
                                        </h6>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                        <Col xs={12} sm={12} md={7}>
                            <Row>
                                <Col md={12}>
                                    <EloGraph key="turnaj" data={graphData(data)} min={min - 5} max={max + 5} color=
                                        {data.team.points >= eloStart ? '#1cda61' : '#ff0026'} liga={false}/>
                                </Col>
                                {data.turnament.seasonElo &&
                                <Col md={12}>
                                    <EloGraph key="season" data={seasonGraphData(data)} min={seasonMin - 5} max={seasonMax + 5}
                                              color={data.team.seasonPoints >= seasonEloStart ? '#1cda61' : '#ff0026'}
                                              liga={true} season={true}/>
                                </Col>
                                }
                                {data.turnament.ligaElo &&
                                <Col md={12}>
                                    <EloGraph key="liga" data={ligaGraphData(data)} min={ligaMin - 5} max={ligaMax + 5}
                                              color={data.team.ligaPoints >= ligaEloStart ? '#1cda61' : '#ff0026'}
                                              liga={true}/>
                                </Col>
                                }
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}><MainView/></Col>
                    </Row>
                </Col>
                <Col xs={12} md={4}>
                    <TeamsView/>
                </Col>
            </Row>
        </div>
    )
};


interface PointsProps {
    num: number;
    ligaNum: number;
    seasonNum: number;
}

const ChangedPoints: React.FC<PointsProps> = (props) => {

    let badge;
    let ligaBadge;
    let seasonBadge;
    if (props.num > 0) {
        badge = <Badge variant={"success"} pill>+{props.num}</Badge>;
    } else if (props.num === 0) {
        badge = <Badge variant={"warning"} pill>{props.num}</Badge>;
    } else {
        badge = <Badge variant={"danger"} pill>{props.num}</Badge>;
    }

    if (props.ligaNum !== undefined) {
        if (props.ligaNum > 0) {
            ligaBadge = <Badge variant={"secondary"} pill>+{props.ligaNum}</Badge>;
        } else if (props.ligaNum === 0) {
            ligaBadge = <Badge variant={"secondary"} pill>{props.ligaNum}</Badge>;
        } else {
            ligaBadge = <Badge variant={"secondary"} pill>{props.ligaNum}</Badge>;
        }
    }

    if (props.seasonNum !== undefined) {
        if (props.seasonNum > 0) {
            seasonBadge = <Badge variant={"info"} pill>+{props.seasonNum}</Badge>;
        } else if (props.ligaNum === 0) {
            seasonBadge = <Badge variant={"info"} pill>{props.seasonNum}</Badge>;
        } else {
            seasonBadge = <Badge variant={"info"} pill>{props.seasonNum}</Badge>;
        }
    }

    return (
        <span>
            {badge} {seasonBadge} {ligaBadge}
        </span>
    )
};


export default TeamStatsView;