import React from 'react';
import {useHistory} from "react-router-dom";

const Logout = () => {

    const history = useHistory();

    localStorage.clear();
    history.push('/');
    document.cookie = 'tokens=; Max-Age=0; path=/; domain=' + window.location.host;

    return(
      <b>Logout</b>
    );
}

export default Logout;