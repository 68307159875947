import React from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import DashView from "./views/Dashboard";
import Login from "./views/login";
import {AuthRoutes, NonAuthRoutes} from "./enumerations";
import PrivateRoute from './containers/PrivateRoute';
import Logout from "./views/logout";
import {DefaultTheme, ThemeProvider} from "styled-components";
import usePersistedState from "./utils/usePersistedState";
import dark from "./styles/themes/dark";
import light from "./styles/themes/light";
import GlobalStyles from './styles/global';
import Header from "./views/Header";
import HomeView from "./views/Home";
import View404 from "./views/404";
import TeamStatsView from "./views/TeamStats";

function App() {

    const [theme, setTheme] = usePersistedState<DefaultTheme>('theme', light);
    const [refresInt, setRefreshInt] = usePersistedState('refreshInt', 60000);

    const toggleTheme = () => {
        setTheme(theme.title === 'light' ? dark : light);
    }

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <GlobalStyles/>
                <Header toggleTheme={toggleTheme}/>
                <Switch>
                    <Route exact path={NonAuthRoutes.home} component={HomeView}/>
                    <Route exact path={NonAuthRoutes.teamStats} component={TeamStatsView}/>
                    <Route exact path={NonAuthRoutes.login} component={Login}/>
                    <Route exact path={NonAuthRoutes.logout} component={Logout}/>
                    <PrivateRoute exact path={AuthRoutes.dashboard} Component={DashView}/>
                    <Route component={View404}/>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;