export default {
  title: 'light',

  colors: {
    name: 'Light',
    primary: '#85be2f',
    lighter: '#cbcbcb',
    secondary: '#498400',
    third: '#e8e8e8',

    background: '#F5F5F5',
    text: '#333',
    link: '#020c52',
  },

};