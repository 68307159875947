import styled from 'styled-components';

export const Container = styled.div`
  height: 60px;
  @media (max-width: 768px) {
    height: 80px;
  }
  background: ${props => props.theme.colors.primary};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
`;