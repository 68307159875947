import React, {useContext} from "react";
import {useQuery} from "@apollo/client";
import {TABLES} from "./gql/turnament";
import {ThemeContext} from "styled-components";
import BeerTableView from "./Table"

interface ITableName {
    name: string;
    id: number;
}

const TablesView: React.FC = () => {
    const {loading, data} = useQuery(TABLES);
    const {colors, title} = useContext(ThemeContext);

    if (loading) return <p>Loading...</p>;


    return (
        <div>
            {data.tables.map((tableName:ITableName) => (
                <BeerTableView key={tableName.id} name={tableName.name} table_id={tableName.id}/>
            ))}
        </div>
    );

};

export default TablesView;