import React, {useContext, useEffect, useState} from 'react';
import {useQuery} from "@apollo/client";
import {allTeams} from "./gql/teams";
import {Badge, Table} from "react-bootstrap";
import {ThemeContext} from "styled-components";
import {Collapse} from "react-collapse";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import usePersistedState from "../utils/usePersistedState";
import {Link} from "react-router-dom";

interface Team {
    id: number;
    points: number;
    active: boolean;
    ligaPoints: number;
    seasonPoints: number;
    team: string;
    tags: Array<string>;
    playedGames: number;
}


const TeamsView: React.FC = (props: any) => {
    const [refresInt, setRefresInt] = usePersistedState('refreshInt', 60000);
    const {loading, data} = useQuery(allTeams,
        {
            pollInterval: refresInt,
            fetchPolicy: "cache-and-network"
        });
    const {colors, title} = useContext(ThemeContext);

    const [toggle, setToggle] = useState<boolean>(true);

    if (loading) return <p>Loading...</p>;

    return (
        <div className="pb-5">
            <div className="machinelist-collapse-title" onClick={() => setToggle(!toggle)}>
                <FontAwesomeIcon icon={faBars}/> Celkové výsledky
            </div>
            <Collapse isOpened={toggle}>
                <Table striped bordered variant={title}>
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th>Tým</th>
                        {data.turnament.ligaElo && <th className="text-center">Turnaj ELO</th>}
                        {data.turnament.ligaElo && !data.turnament.seasonElo && <th className="text-center">Liga ELO</th>}
                        {data.turnament.ligaElo && data.turnament.seasonElo && <th className="text-center">Season ELO</th>}
                        {!data.turnament.ligaElo && <th className="text-center">Elo</th>}

                        <th className="text-center">Played {getPlayedGames(data.allTeams)}/{Math.round(((data.allTeams.length * data.turnament.maxRounds) / 2))}</th>
                    </tr>
                    </thead>
                    <tbody>

                    {data.allTeams.map((team: Team, index: number) => (
                        <tr key={team.id}>
                            <td>{index + 1}</td>

                            <td>{!team.active && !team.tags.includes('first') && !team.tags.includes('second')
                                    && !team.tags.includes('third') && <span className="dot"></span>}
                                {team.tags.includes('first') && <span className="medfirst">1</span>}
                                {team.tags.includes('second') && <span className="medsecond">2</span>}
                                {team.tags.includes('third') && <span className="medthird">3</span>}
                                <Link to={`/team/${team.id}`}>{team.team}</Link>
                            </td>
                            {data.turnament.ligaElo && <td className="text-center fon"><Badge className="f20"
                                                                                              variant="primary">{team.points}</Badge>
                            </td>}
                            {data.turnament.ligaElo && !data.turnament.seasonElo && <td className="text-center"><Badge className="f20"
                                                                                          variant="secondary">{team.ligaPoints}</Badge>
                            </td>}
                            {data.turnament.ligaElo && data.turnament.seasonElo && <td className="text-center"><Badge className="f20"
                                                                                          variant="secondary">{team.seasonPoints}</Badge>
                            </td>}
                            {!data.turnament.ligaElo && <td className="text-center">{team.points}</td>}

                            <td className="text-center">{team.playedGames}</td>
                        </tr>
                    ))}

                    </tbody>
                </Table>
            </Collapse>
        </div>
    );

};

const getPlayedGames = (allTeams: Array<Team>) => {
    let num = 0;
    allTeams.map((team: Team) => {
        num += team.playedGames
    });

    return num / 2;
}

export default TeamsView;