export default {
    title: 'dark',

    colors: {
        primary: '#333',
        lighter: '#313131',
        secondary: '#85be2f',
        third: '#414141',

        background: '#222',
        text: '#fff',
        link: '#85be2f',
    },

};