import {gql} from "@apollo/client";

export const allTeams = gql`
{
  allTeams {
    id, team, points, ligaPoints, seasonPoints, playedGames, active, tags
  }
  turnament{
    maxRounds, ligaElo, seasonElo
  }
}
`

export const gqlTeamResults = gql`
query teamResults($teamId: Int!){
  teamResults(teamId: $teamId){
    team1{
        id, team, active
    },
    team2{
        id, team, active
    },
    id,
    startPointsTeam1,
    startPointsTeam2,
    scoreTeam1,
    scoreTeam2,
    team1EloChange,
    team2EloChange,
    ligaStartPointsTeam1,
    ligaStartPointsTeam2,
    ligaTeam1EloChange,
    ligaTeam2EloChange
    seasonStartPointsTeam1,
    seasonStartPointsTeam2,
    seasonTeam1EloChange,
    seasonTeam2EloChange
  }
  team(teamId:$teamId){
    id, team, points, ligaPoints, seasonPoints, playedGames, player1, player2
  }
  turnament{
    maxRounds, matchMinutes, ligaElo, seasonElo
  }
}
`