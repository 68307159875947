import React, {useContext} from 'react';
import {useQuery} from "@apollo/client";
import {TURNAMENT_INFO_MESSAGE} from "./gql/turnament";
import {ThemeContext} from "styled-components";


const MessageView: React.FC = () => {
    const {loading, error, data, refetch} = useQuery(TURNAMENT_INFO_MESSAGE);
    const {colors, title} = useContext(ThemeContext);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error on Main :(</p>;

    return (
        <div className="text-center">
            <h1>{data.turnament.statusType.message}</h1>
        </div>
    );

};

export default MessageView;