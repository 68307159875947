/*
 * ENUMERATIONS
 * Enums allow us to organize a collection of related values. Think of them as
 * a class for values, wherein the value can only be a string , or number.
 *
 */


export enum AuthRoutes {
    dashboard = '/dashboard',
}

export enum NonAuthRoutes {
    home = '/',
    login = '/login',
    logout = '/logout',
    teamStats = '/team/:id',
    page403 = '/403',
    page404 = '/404',
    page500 = '/500',
}

export enum UserRoles {
    superAdmin = 'superAdmin',
    admin = 'admin',
    nonAdmin = 'nonAdmin'
}

//some views will be for admins only, some for users (non-admins)
// and then the rest is available for all roles
export const userRoles = {
    admins: [String(UserRoles.superAdmin), String(UserRoles.admin)],
    users: [String(UserRoles.nonAdmin)],
    all: [
        String(UserRoles.superAdmin),
        String(UserRoles.admin),
        String(UserRoles.nonAdmin)
    ]
};