import React, {useContext} from 'react';
import {ThemeContext} from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {BeerTableStylePlay, BeerTableStyles} from "./styles"
import UserContext from "../../context/user";
import {GAMES} from "../gql/turnament";
import {useQuery} from "@apollo/client";
import usePersistedState from "../../utils/usePersistedState";
import MatchView from "./MatchView";
import {Chip} from "@mui/material";

interface IProps {
    name: string;
    table_id: number;
}

const BeerTableView: React.FC<IProps> = ({name, table_id}) => {
    const [refresInt, setRefresInt] = usePersistedState('refreshInt', 60000);
    const {loading, error, data} = useQuery(GAMES, {variables: {tableId: table_id}, pollInterval: refresInt});
    const {colors, title} = useContext(ThemeContext);

    const userLogged = useContext(UserContext);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error on table :( </p>;

    return (
        <BeerTableStyles>
            {userLogged &&
            <div>Admin</div>
            }
            <Container>
                <Row className="justify-content-center">
                    <Col xs={4} md={4} className="text-center"></Col>
                    <Col xs={4} md={4} className="text-center"><h4>{name}</h4></Col>
                    <Col xs={4} md={4} className="text-center"></Col>
                </Row>
                {data.allGames.length > 0 &&
                <div style={{textAlign: 'center', marginBottom: '-17px'}}>
                    <Chip label="Právě hraje" variant="outlined" size="small" style={{background: '#d8eacf'}}/>
                </div>
                }
                <MatchView data={data} index={0}/>
                {data.allGames.length > 1 &&
                <span>
                    <div style={{textAlign: 'center', marginBottom: '-17px'}}>
                        <Chip label="Připravení" variant="outlined" size="small" style={{background: '#FFFFFF'}}/>
                    </div>
                    <MatchView data={data} index={1}/>
                </span>
                }
                {data.allGames.length > 2 &&
                <span>
                    <div style={{textAlign: 'center', marginBottom: '-17px'}}>
                        <Chip label="A další" variant="outlined" size="small" style={{background: '#FFFFFF'}}/>
                    </div>
                    <MatchView data={data} index={2}/>
                </span>
                }
            </Container>
        </BeerTableStyles>
    );
};


export default BeerTableView;