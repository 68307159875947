

// module for saving tokens to local storage
import UserContext from "../context/user";

const TOKEN_KEY = "tokens";
// tokens = { accessToken: "xyz", refreshToken: "abc" }
export function saveTokens(tokens:object) {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(tokens));
  document.cookie = "tokens=" + JSON.stringify(tokens) + ";"
}

type TokensType = {
  token: string
  refreshToken:  string
};

export function getTokens(): TokensType | null{
  let token = localStorage.getItem(TOKEN_KEY);
  if(token){
    return JSON.parse(token);
  }else{
    return null
  }

}

export function deleteTokens() {
  localStorage.removeItem(TOKEN_KEY);
}