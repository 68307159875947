import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {getTokens} from "./core/tokens";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const tokens = getTokens();

    if (tokens) {
        return {
            headers: {
                ...headers,
            }
        }
    } else {
        return {
            headers: {
                ...headers,
            }
        }
    }

});

// TODO: load from env
const URI = 'https://apibeerpong.jablhol.cz/graphql';
// const URI = 'http://localhost:8000/graphql';

const httpLink = new HttpLink({
    uri: URI,
});

const auth_client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

// apibeerpong is production ulr
if (URI.includes('apibeerpong')){
    Sentry.init({
      dsn: "https://2dd8cae6a397455d80486b67f85a1978@sentry.balitech.cz/5",
      integrations: [new BrowserTracing()],
      release: '0.2.0', // TODO load from env
      tracesSampleRate: 0.1,
    });
}

ReactDOM.render(
    <ApolloProvider client={auth_client}>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </ApolloProvider>,
    document.getElementById('root')
);
