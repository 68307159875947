import React, {useContext} from 'react';
import {useQuery} from "@apollo/client";
import {TURNAMENT_INFO} from "./gql/turnament";
import {ThemeContext} from "styled-components";
import MessageView from "./Messega";
import TablesView from "./Tables";
import usePersistedState from "../utils/usePersistedState";
import {Col, Row} from "react-bootstrap";

interface Turnament {
    id: number;
    status: object;
    maxRounds: number;
}


const MainView: React.FC = () => {
    const [refresInt, setRefresInt] = usePersistedState('refreshInt', 60000);
    const {loading, data} = useQuery(TURNAMENT_INFO,
        {
            pollInterval: refresInt,
            fetchPolicy: "cache-and-network"
        });
    const {colors, title} = useContext(ThemeContext);

    if (loading) return <p className="text-center">Loading...</p>;

    let view;

    if (data.turnament.statusType.strId === 'PLAY_ELO_QUALIFICATION') {
        view = <TablesView/>;

    } else if (data.turnament.statusType.strId === 'PLAYOFF_8' || data.turnament.statusType.strId === 'PLAYOFF_16') {
        view = <TablesView/>;
    } else {
        view = <MessageView/>;
    }

    return (
        <div>
            {view}
{/*             <p> */}
{/*                 <h5 className="pb-0 mb-0">Partner ligy:</h5> */}
{/*             </p> */}
{/*             <Row> */}
{/*                 <Col xs={12} md={6} className="text-center"> */}
{/*                     <a href="https://pivniburza.cz/" target="_blank"> */}
{/*                         {title == 'dark' && <img src="/burzawhite.png" width="150px" className="d-inline-block"/>} */}
{/*                         {title == 'light' && <img src="/burzablack.png" width="150px" className="d-inline-block"/>} */}
{/*                     </a> */}
{/*                     <p> */}
{/*                         <a href="https://pivniburza.cz/" target="_blank">pivniburza.cz</a> */}
{/*                     </p> */}
{/*                 </Col> */}
                {/*<Col xs={12} md={6} className="text-center">*/}
                {/*    <a href="https://nabytekmatyas.cz/" target="_blank">*/}
                {/*        <img src="/matyasLogo.png" width="250px" className="d-inline-block"/>*/}
                {/*    </a>*/}
                {/*</Col>*/}
{/*             </Row> */}

        </div>
    );

};

export default MainView;