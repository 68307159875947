import React from 'react';
import { LineChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';

const EloGraph: React.FC<any> = (props) => {

    const data = props.data;
    const min = props.min;
    const max = props.max;
    const color = props.color;
    const liga = props.liga;
    const season = props.season;

    return (
        <ResponsiveContainer width="100%" aspect={2}>
        <LineChart
          width={400}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 10,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[min, max]} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey={liga ? season ? 'SeasonElo' : 'LigaElo' : 'elo'} stroke={color} />
        </LineChart>
      </ResponsiveContainer>
    );
}

export default EloGraph;