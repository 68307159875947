import React from 'react';
import {BeerTableStylePlay} from "./styles";
import {Chip, Grid, Paper, styled, Typography} from "@mui/material";

interface IProps {
    data: any;
    index: number;
}

const MatchView: React.FC<IProps> = ({data, index, ...props}) => {

    const getLable = (jsonString: string) => {
        const lables: Array<string> = JSON.parse(jsonString);

        if (lables.includes('final')) {
            return (
                <div style={{textAlign: 'center'}}>
                    <Chip label="finále" variant="filled" size="small" style={{background: '#64d00b'}}/>
                </div>
            )
        } else if (lables.includes('thirdforth')) {
            return (
                <div style={{textAlign: 'center'}}>
                    <Chip label="o třetí místo" variant="filled" size="small" style={{background: '#813e08'}}/>
                </div>
            )
        } else if (lables.includes('playoff')) {
            return (
                <div style={{textAlign: 'center'}}>
                    <Chip label="playoff" variant="filled" size="small" style={{background: '#9ba6ee'}}/>
                </div>
            )
        } else {
            return (
                <div style={{textAlign: 'center'}}>
                    VS
                </div>
            )
        }
    }
    return (
        <BeerTableStylePlay>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={4}>
                    <div style={{textAlign: 'center'}}>
                        <Typography variant="h5">
                            {data.allGames.length > 0 && data.allGames[index].game.team1.team}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    {data.allGames.length > 0 && getLable(data.allGames[index].tags)}
                </Grid>
                <Grid item xs={12} md={4}>
                    <div style={{textAlign: 'center'}}>
                        <Typography variant="h5">{data.allGames.length > 0 && data.allGames[index].game.team2.team}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </BeerTableStylePlay>
    )

}

export default MatchView;